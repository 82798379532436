
import ReferencesTreeNode from './ReferencesTreeNode.vue'
import EntryApiMixin from './EntryApiMixin.js'

// TODO: sidebar (parent?)
// TODO: buld calls to server, load after calls, error handling?
async function sleep(ms: number) { return new Promise(r => setTimeout(r, ms)) }

export default {
	name: 'ReferencesTree',
	components: { ReferencesTreeNode },
	inject: [ 'endpoint' ],
	mixins: [ EntryApiMixin ],
	props: {
		entryId: String,
		value: Array, // selection
	},
	data: () => ({
		root: null,
		index: {},
		count: 0,
		selection: {},
		selectAll: false,
		selectChanged: false,
		selectDraft: false,
		selectPublished: false,
		collectCount: 0,
	}),
	watch: {
		selection: {
			deep: true,
			handler(n) {
				this.$emit('input', Object.entries(n).filter(v => v[1]).map(v => v[0]))
			},
		},
		selectAll(n) {
			for (const s in this.selection) {
				this.selection[s] = n
			}
			this.selectChanged = n
			this.selectDraft = n
			this.selectPublished = n
		},
		// TODO: to get closer to CF behaviour we could keep track if all/none of a certain category
		//       are selected and if so, check/uncheck the corresponding model
		selectChanged(n) {
			for (const s in this.selection) {
				if (this.index[s].status != 'changed') continue
				this.selection[s] = n
			}
		},
		selectDraft(n) {
			for (const s in this.selection) {
				if (this.index[s].status != 'draft') continue
				this.selection[s] = n
			}
		},
		selectPublished(n) {
			for (const s in this.selection) {
				if (this.index[s].status != 'published') continue
				this.selection[s] = n
			}
		},
	},
	methods: {
		// TODO: refactor: move this func to a global place and reference all places that need this functionality there
		getStatus(entry) {
			if (!entry || !entry.sys.updatedAt) return 'loading..'
			if (entry.sys.archivedAt) return 'archived'
			if (!entry.sys.publishedAt) return 'draft'
			if (entry.sys.updatedAt > entry.sys.publishedAt) return 'changed'
			return 'published'
		},
		toNode(entry) {
			// TODO: find and link type!
			// TODO: set title
			return { id: entry.sys.id, entry, children: [], status: this.getStatus(entry) }
		},
		findLinks(o, cb = e => e, maxDepth = 10) {
			if (maxDepth == 0) return []
			if (o?.sys?.type == 'Link') return [ cb(o) ]
			const r = []
			for (const i in o) r.push(...this.findLinks(o[i], cb, maxDepth-1))
			return r
		},
		collect(node, index, path) {
			if (path.length > 10) {
				console.warn('SAFETY BREAK (path length) AT', path)
				return
			}
			if (path.length == 1) this.collectCount = 0
			this.collectCount++
			if (this.collectCount > 100) {
				console.warn('SAFETY BREAK (call count) AT', path)
				return
			}

			const childIdIndex = {}
			const add = (id) => {
				if (id) {
					let child = index[id]
					if (!child) {
						console.warn('cannot find child', id, 'in', index)
						return
					}
					if (path.includes(id)) {
						// do not go deeper if the child is already in the path
						child = Object.assign({ repeat: true }, child)
					}
					else {
						this.collect(child, index, [...path, id])
					}
					// we dont want duplicates in the result list
					if (childIdIndex[child.id]) return
					childIdIndex[child.id] = true
					node.children.push(child)
				}
			}

			// TODO: instead go through controls?
			for (const field in node?.entry?.fields) {
				const values = node?.entry?.fields[field]
				for (const locale in values) {
					const value = values[locale]
					const id = value?.sys?.id
					if (id) {
						add(id)
					}
					else if (Array.isArray(value)) {
						for (const item of value ?? []) {
							add(item?.sys?.id)
						}
					}
					else if (value?.nodeType == 'document') {
						// TODO: go through rich text also!
						const links = this.findLinks(value)
						for (const item of links ?? []) {
							add(item?.sys?.id)
						}
					}
				}
			}
		},
		async load() {
			// TODO: move to EntryApiMixin?
			const request = {
				include: 10
			}
			const r = await this.$httpGet(this.endpoint + '/entries/' + this.entryId + '/references', request)
			const index: any = {}
			for (const error of r.errors ?? []) {
				index[error.details.id] = this.toNode(error)
				index[error.details.id].id = error.details.id
			}
			for (const entry of r.includes?.Entry ?? []) {
				index[entry.sys.id] = this.toNode(entry)
			}
			for (const asset of r.includes?.Asset ?? []) {
				index[asset.sys.id] = this.toNode(asset)
			}
			for (const item of r.items) {
				index[item.sys.id] = this.toNode(item)
				this.root = index[item.sys.id]
			}
			this.collect(this.root, index, [ this.root.id ])
			this.index = index
			this.count = Object.keys(index).length

			const selection = {}
			Object.values(index).forEach((node: any) => {
				if (node.entry?.sys?.type == 'error') return
				if (node.repeat) return
				selection[node.id] = false
			})
			this.selection = selection
		},
		async bulkPublish() {
			// TODO: show dialog?
			if (!window.confirm(`Publish ${ this.value.length } entries?`)) return
			for (const entryId of this.value) {
				const node = this.index[entryId]
				if (node.status == 'published') continue
				const entry = node.entry
				//console.log('publishing', entry.sys.type); await sleep(1000)
				if (entry.sys.type == 'Entry') await this.publish(entry)
				if (entry.sys.type == 'Asset') await this.publishAsset(entry)
			}
			// TODO: is this necessary? or should we apply the new state to all selected entries?
			this.load()
		},
		forwardSubedit(link, callback) {
			this.$emit('subedit', link, callback)
		},
	},
	mounted() {
		this.load()
	},
}
