
import Field from './fields/Field.vue'
import EntryStatus from './fields/EntryStatus.vue'
import { formatDate, userName } from '../utils'
import Spinner from './Spinner.vue'
import SpinnerAnimation from './SpinnerAnimation.vue'
import TranslationSidebar from './TranslationSidebar.vue'
import VersionSidebar from './VersionSidebar.vue'
import ImporterMessageSidebar from './ImporterMessageSidebar.vue'
import Head from '../views/Head.vue'
import Menu from '../components/Menu.vue'
import Info from '../views/Info.vue'
import EntryApiMixin from './EntryApiMixin.js'
import SystemTags from './fields/SystemTags.vue'
import Dialog from '../components/Dialog.vue'
import ReferencesTree from '../components/ReferencesTree.vue'
import Comments from './sidebar/Comments.vue'
import Tasks from './sidebar/Tasks.vue'
import DeeplTranslation from './sidebar/DeeplTranslation.vue'
import { addTranslationItem } from './sidebar/deepl'
import Preview from './Preview.vue'
import PublishButton from './PublishButton.vue'
import EntryAutoSaveMixin from './EntryAutoSaveMixin'
import EntryConflicts from './EntryConflicts.vue'
import ActionButton from './ActionButton.vue'
import AdditionalFields from '../components/fields/AdditionalFields.vue'

export default {
	name: 'EntryEditor',
	components: { Field, EntryStatus, Spinner, SpinnerAnimation, TranslationSidebar, VersionSidebar, ImporterMessageSidebar, Head, Menu, Info, SystemTags, Dialog, ReferencesTree, Comments, Tasks, Preview, DeeplTranslation, PublishButton, EntryConflicts, ActionButton, AdditionalFields },
	inject: [ 'base', 'endpoint', 'environment', 'space', 'locales', 'defaultLocale', 'fallbackLocale', 'permissions', 'eventBus' ],
	mixins: [ EntryApiMixin, EntryAutoSaveMixin ],
	props: {
		entryId: String,
		value: [ Number, String ],
	},
	data: () => ({
		entryLoader: null,
		initialLoading: true,
		loadingAction: null,
		tab: 'editor',
		sidebarTab: 'general',
		referencesSelection: [],
		selectedLocaleCodes: [],
		showDisabledFields: false,
		translationItems: [],
		error: null,
		original: null,
		fieldGroupsExpanded: [ 'General' ],
		conflicts: null,
		sidebarVisible: false,
	}),
	computed: {
		contentType() {
			if (!this.model?.sys) return undefined
			const id = this.model.sys.contentType.sys.id
			return this.typeLookup?.[id]
		},
		typeFieldsById() {
			const r = {}
			for (const field of this.contentType?.fields ?? []) {
				r[field.id] = field
			}
			return r
		},
		title() {
			const entry = this.model
			const df = this.contentType?.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
		controlsMerged() {
			const lookup = {} // fieldId -> control
			for (const control of this.editorInterface?.controls ?? [])
				lookup[control.fieldId] = control
			// TODO: we also add the fields defined on the CT
			//       should we mark those somehow, as there are no controls defined for them?
			for (const field of this.contentType.fields) {
				if (!lookup[field.id]) {
					lookup[field.id] = { fieldId: field.id, field, settings: {} }
				}
			}
			return this.contentType.fields.map(field => {
				return { ...lookup[field.id], field }
			})
		},
		localeLookup() {
			const r = {}
			for (const locale of this.locales)
				r[locale.code] = locale
			return r
		},
		fieldGroups() {
			const r = {}
			for (const control of this.controlsMerged) {
				let name = control.settings?.group
				if (!name) name = 'General'
				if (name == 'hidden') continue
				const group = r[name] ?? { name, controls: [] }
				group.controls.push(control)
				r[name] = group
			}
			return Object.values(r)
		},
		iCanUpdate() {
			return this.permissions.iCanUpdate(this.model)
		},
	},
	watch: {
		errors(n) {
			if (n)
				this.$refs.errors.open()
			else
				this.$refs.errors.close()
		},
	},
	methods: {
		userName,
		getTitle(entry) {
			const ct = window['typeLookup']?.[entry?.sys?.contentType?.sys?.id]
			const df = ct?.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
		// TODO: should be loaded globally, probably not in the entryEditor - at least check if we have already loaded it into window
		autoSave(value, control, locale) {
			// TODO: this exploits the effect that selectedLocaleCodes is set at the end of mounted..
			// this prevents that we get change events during startup. this gets rid of MOST init updates.
			if (this.selectedLocaleCodes.length == 0) return

			if (control) {
				const field = this.typeFieldsById[control.fieldId]
				const localeCodes = this.locales.map(l => l.code)
				addTranslationItem(this.translationItems, value, field, control, locale, localeCodes, this.original)
			}

			this.entryAutoSave()
		},
		handleConflicts(conflicts, callback) {
			this.conflicts = conflicts
			this.$refs.conflicts.open(callback)
		},
		formatDate,
		forwardSubedit(link, callback) {
			if (link.linkType == 'Asset') {
				this.$emit('subeditAsset', link, callback)
				return
			}
			this.$emit('subedit', link, callback)
		},
		forwardSubeditAsset(link, callback) {
			this.$emit('subeditAsset', link, callback)
		},
		async bulkPublish() {
			this.loadingAction = 'publish'
			await this.$refs.referencesTree.bulkPublish()
			this.loadingAction = null
		},
		async duplicate() {
			const typeId = this.model.sys.contentType.sys.id
			const model = JSON.parse(JSON.stringify(this.model))
			delete model.sys
			if (this.model?.fields?.title) {
				for (const locale in model.fields.title) {
					model.fields.title[locale] = model.fields.title[locale] + ' (copy)'
				}
			}
			const entry = await this.createEntry(typeId, model)
			// TODO: navigation is being skipped by the containing StackingView
			//this.$router.push(this.base + '/entries/' + entry.sys.id)
			this.$emit('subedit', { id: entry.sys.id, linkType: 'Entry' })
		},
		async del() {
			if (!confirm('Are you sure you want to delete this entry?')) return
			this.deleteEntry()
			this.$emit('close', this.model)
		},
		async createNew() {
			const entry = await this.createEntry(this.contentType.sys.id)
			this.$emit('subedit', { id: entry.sys.id, linkType: 'Entry' })
		},
		scrollIntoView(ref) {
			// TODO: fields.xxx.de
			// TODO: turn on locale? what does cf do?
			// TODO: locate the right field
			// TODO: scroll field into view
			// TODO: mark field somehow
		},
		// WIP ADJ-21880
		// but probably futile, as we can only collect the errors for visible fields
		// still maybe a slight improvement that helps the user.
		getErrors() {
			const r = []
			for (const field of this.$refs.field) {
				const errors = field.getErrors()
				for (const error of errors) {
					r.push({
						field,
						locale: this.localeLookup[field.locale],
						error,
					})
				}
			}
			return r
		},
		validateAndPublish(event) {
			if (!event.metaKey) {
				const errors = this.getErrors()
				if (errors.length) {
					//alert('Validation failed. Please fix the errors before publishing!')
					errors[0].field.$el.scrollIntoView({ behavior: 'smooth' })
					return
				}
			}
			this.publish()
		},
	},
	async mounted() {
		await this.loadContentTypes()
		try {
			await this.loadEntry()
		}
		catch (e) {
			console.error('EntryEditor: could not load', this.entryId, e)
			this.error = e.message
			return
		}
		if (!this.contentType) console.error(`Content type ${ this.model.sys.contentType.sys.id } not found!`)
		document.title = this.title
		await this.loadEditorInterface()
		this.initialLoading = false
		this.loadReferences()

		// init autosave mixin
		this.initAutoSave()

		this.original = JSON.parse(JSON.stringify(this.model))
	},
}
