export const emptyRteValue = ({ nodeType: 'document', data: {}, content: [{ nodeType: 'paragraph', data: {}, content: [{ nodeType: 'text', value: '', marks: [], data: {}}]}]})
export const emptyRteValueLength = JSON.stringify(emptyRteValue).length

export function formatDate(dateString: string, format = 'full') {
	try {
		if (format == 'short') {
			const d = new Date(dateString)
			const diff = new Date().getTime() - d.getTime()
			if (diff < -1000 * 60 * 60 * 24) return d.toISOString().substring(2, 10)
			if (diff < -1000 * 60 * 60) return 'in ' + Math.floor(-diff / 1000 / 60 / 60) + 'h'
			if (diff < -1000 * 60) return 'in ' + Math.floor(-diff / 1000 / 60) + 'm'
			if (diff < 0) return 'now'
			if (diff < 1000 * 60) return 'recently'
			if (diff < 1000 * 60 * 60) return Math.floor(diff / 1000 / 60) + 'm ago'
			if (diff < 1000 * 60 * 60 * 24) return Math.floor(diff / 1000 / 60 / 60) + 'h ago'
			return d.toISOString().substring(2, 10)
		}
		const d = new Date(dateString)
		const diff = new Date().getTime() - d.getTime()
		if (diff < -1000 * 60 * 60 * 24) return d.toDateString()
		if (diff < -1000 * 60 * 60) return 'in ' + Math.floor(-diff / 1000 / 60 / 60) + ' hours'
		if (diff < -1000 * 60) return 'in ' + Math.floor(-diff / 1000 / 60) + ' minutes'
		if (diff < 0) return 'now'
		if (diff < 1000 * 60) return 'recently'
		if (diff < 1000 * 60 * 60) return Math.floor(diff / 1000 / 60) + ' minutes ago'
		if (diff < 1000 * 60 * 60 * 24) return Math.floor(diff / 1000 / 60 / 60) + ' hours ago'
		return d.toDateString()
	}
	catch (e) {
		console.error('formatDate', e)
		return dateString
	}
}

export function formatDatePoint(dateString: string) {
	try {
		const d = new Date(dateString)
		return d.toDateString()
	}
	catch (e) {
		console.error('formatDatePoint', e)
		return dateString
	}
}

export function formatTimePoint(dateString: string) {
	try {
		const d = new Date(dateString)
		return d.toLocaleTimeString().slice(0, 5)
	}
	catch (e) {
		console.error('formatTimePoint', e)
		return dateString
	}
}

export function getUsers() {
	if (!(window as any).userLookup) return []
	return Object.values((window as any).userLookup)
}

export function userName(id: string, format = 'full') {
	const u = (window as any).userLookup?.[id]
	if (format == 'short') return (u?.firstName?.[0] ?? '?') + (u?.lastName?.[0] ?? '?')
	return u ? (u.firstName + ' ' + u.lastName) : (id)
}

export function userEmail(id: string, format = 'full') {
	const u = (window as any).userLookup?.[id]
	if (format == 'short') return u?.email?.replace?.(/([^\.-@])[^\.-@]+/g, '$1') ?? '?'
	return u ? (u.email) : (id)
}

export function escapeRegExp(string) {
	return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function getValueState(value: any) {
	if (value === undefined) return ''
	if (typeof value != 'string') value = JSON.stringify(value)
	return value
		// we remove empty array content, as our controls create this automatically, but it does not actually represent a change
		.replace(/,?"[^"]+":\[\]/g, '')
		// we remove empty strings for the same reason
		.replace(/,?"[^"]+":""/g, '')
		// and nulls
		.replace(/,?"[^"]+":null/g, '')
		// same for RTE empty states
		.replace(/,?"data":\{\}/g, '')
		.replace(/,?"value":""/g, '')
		.replace(/,?"marks":\[\]/g, '')
		.replace(new RegExp(',?"[^"]+":' + escapeRegExp('{"nodeType":"document","content":[{"nodeType":"paragraph","content":[{"nodeType":"text"}]}]}'), 'g'), '')
		// the commas after the removed parts were not removed - this can lead to '{,'
		.replace(/\{,/g, '{')
		// we ignore seconds
		.replace(/(T\d\d:\d\d):\d\d/g, '$1')
		.replace(/T00:00:00[+\-]\d\d:\d\d/g, '')
		.replace(/T00:00[+\-]\d\d:\d\d/g, '')
		.replace(/:00[+\-]\d\d:\d\d/g, '')
}

// get a string representing the entry state for comparison
// this is being used to detect content changes
export function getEntryState(entry): any {
	if (!entry) return ''
	const state = JSON.stringify({ fields: entry.fields, metadata: entry.metadata })
	return getValueState(state)
}

export function isFileType(file, mime, ext, defaultLocale) {
	const ct = file?.contentType
	const url = file?.url
	return ct?.startsWith(mime) || new RegExp('\\.(' + ext + ')$', 'i').test(url)
}

export function isImage(entry, defaultLocale) {
	return isFileType(entry?.fields?.file?.[defaultLocale], 'image/', 'jpeg|jpg|gif|png|svg', defaultLocale)
}

export function isPdf(entry, defaultLocale) {
	return isFileType(entry?.fields?.file?.[defaultLocale], 'application/pdf', 'pdf', defaultLocale)
}

export function isAudio(entry, defaultLocale) {
	return isFileType(entry?.fields?.file?.[defaultLocale], 'audio/', 'mp3', defaultLocale)
}

export function isVideo(entry, defaultLocale) {
	return isFileType(entry?.fields?.file?.[defaultLocale], 'video/', 'mp4|webm|ogg', defaultLocale)
}

export function isCode(entry, defaultLocale) {
	return isFileType(entry?.fields?.file?.[defaultLocale], 'application/', 'json', defaultLocale)
}

export function isText(entry, defaultLocale) {
	return isFileType(entry?.fields?.file?.[defaultLocale], 'text/', 'txt', defaultLocale)
}
