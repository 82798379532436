<template>
	<div class="NodeGraph">
		<div v-if="graph" class="canvas" ref="canvas"
			:class="{
				zoom100: zoom >= 1.0,
				zoom150: zoom >= 1.5,
				zoom200: zoom >= 2.0,
			}"
			@contextmenu="contextmenu"
			@click="clickCanvas"
			@mousedown="mousedown"
			@mousemove="mousemove"
			@wheel="handleWheel"
			:style="{
				cursor: mode == 'WHEELZOOM' ? (dragging ? 'grabbing' : 'grab') : 'default',
			}"
		>
			<div class="content"
				:style="{
					transformOrigin: '0 0',
					transform: 'translate(' + num(pos.x) + 'px, ' + num(pos.y) + 'px) scale(' + num(zoom) + ')',
				}"
			>
				<div class="selectionMarquee" v-if="mode == 'NATURAL' && wasDragging && dragging && draggingStart"
					:style="{
						left: marqueeXStart + 'px',
						top: marqueeYStart + 'px',
						width: (marqueeXEnd - marqueeXStart) + 'px',
						height: (marqueeYEnd - marqueeYStart) + 'px',
					}"
				></div>
				<ArrowDragger :zoom="zoom" :pos="pos" ref="arrowDragger"
					@addLink="addLink"
				/>
				<!-- TODO: we are sending the mousePos to every node, actually we only need it while the node is dragging -->
				<Node v-for="node of graph.fields.nodes[defaultLocale]" :key="node.entryId"
					:value="node"
					:entry="entryLookup[node.entryId]"
					:query="node2query[node.entryId]"
					@mouseover="hoverNode = node; $refs.arrowDragger.mouseoverNode(node, $event)"
					@mouseout="hoverNode = null; $refs.arrowDragger.mouseoutNode(node, $event)"
					@mouseup="$refs.arrowDragger.mouseupNode(node, $event)"
					:mousePos="mousePos"
					@select="selectNode"
					:selected="selectedNodes.includes(node)"
					@drag="dragNode"
				/>
				<Link v-for="link of links" :key="link.node1 + '-' + link.node2"
					@mouseover="hoverRef = $event"
					@mouseout="hoverRef = null"
					:value="link"
					:node1="nodeLookup[link.node1]"
					:node2="nodeLookup[link.node2]"
					:query2="node2query[link.node2]"
				/>
			</div>
		</div>
		<GraphMenu ref="menu"
			@zoomBy="zoomBy($event, 0.1)"
			@zoomOut="zoomBy($event, -0.1)"
			@zoomToFit="zoomToFit"
			@removeNode="$event.removed = true; hoverNode = null; $refs.arrowDragger.node = null;"
			@addNode="addNode"
			@removeRel="removeLink"
			@forceLayout="forceLayoutAnimated(1000)"
		/>
		<Sidebar v-if="graph">
			<div>
				<div class="section" style="margin: 10px 0; display: flex; gap: 5px; flex-direction: column;">
					<h2>Graph</h2>
					<input type="text" v-model="graph.fields.title[defaultLocale]" style="padding: 10px; border-radius: 5px; border: 1px solid #ccc; margin-bottom: 7px;" />
					<ActionButton @click="save" class="primary wide">Save</ActionButton>
					<ActionButton @click="storeViewport" class="link">Remember Viewport</ActionButton>
				</div>
			</div>
			<GraphQueries :queries="queries" />
			<div v-if="selectedNodes?.length">
				<div class="section" style="margin: 10px 0; display: flex; gap: 5px; flex-direction: column;">
					<h2>Selection</h2>
					<div v-if="selectedNodes?.length == 1">
						<div v-if="selectedNodes[0].entryId && entryLookup[selectedNodes[0].entryId]">
							<div v-for="entry of [ entryLookup[selectedNodes[0].entryId] ]" :key="entry.sys.id">
								<div style="font-weight: bold;">
									{{ getTitle(entry) }}
									<mdi arrow-top-right-bold-box-outline
										@click="$router.push(base + '/entries/' + entry.sys.id)"
									/>
								</div>
								<div>{{ entry.sys.contentType.sys.id }}</div>
								<div>{{ entry.sys.id }}</div>
								<div v-if="entry?.fields?.properties?.[defaultLocale]" style="margin-top: 10px;">
									<h2>Properties</h2>
									<JsonPropertiesField v-model="entry.fields.properties[defaultLocale]"
										style="font-size: 13px;"
									/>
								</div>
							</div>
						</div>
						<div v-else>
							TODO: some details, editable title?
						</div>
					</div>
					<div v-else>
						<div>{{ selectedNodes.length }} nodes</div>
					</div>
				</div>
			</div>
		</Sidebar>
		<!-- debug panel
		<div style="position: absolute; bottom: 20px; left: 20px;" ref="debug">
			{{ pos.x.toFixed(2) }} | {{ pos.y.toFixed(2) }}<br />
			{{ debugMousePos.x.toFixed(2) }} | {{ debugMousePos.y.toFixed(2) }}<br />
		</div>
		-->
	</div>
</template>

<script>
import Node from './Node.vue'
import Link from './Link.vue'
import ArrowDragger from './ArrowDragger.vue'
import GraphMenu from './GraphMenu.vue'
import GraphQueries from './GraphQueries.vue'
import Sidebar from '../../views/Sidebar.vue'
import { loadEntries } from '../../EntryApi'
import EntryApiMixin from '../EntryApiMixin.js'
import JsonPropertiesField from '../fields/JsonPropertiesField.vue'
import ActionButton from '../ActionButton.vue'

// TODO: title bar only in sidebar with back navigation
// TODO: doubleclick node: overlay entry editor
// TODO: dialog for setting title?
// TODO: different menu on multi selection?
// TODO: always zoom to fit on open instead of storing the viewport?
// TODO: fix: zoomByg is not using mouse as center correctly -> center is drifting when zoomByg in/out
// TODO: show a list of removed nodes / links so user can restore them
//       or turn on "show deleted" mode where these would be highlighted in red

export default {
	name: 'NodeGraph',
	mixins: [ EntryApiMixin ],
	components: { Node, Link, ArrowDragger, GraphMenu, GraphQueries, Sidebar, JsonPropertiesField, ActionButton },
	inject: [ 'base', 'endpoint', 'defaultLocale', 'fallbackLocale' ],
	data: () => ({
		graph: null,
		// [{ query, entries }]
		queryResults: [],
		mml: null,
		mul: null,
		elPos: { x: 0, y: 40 },
		zoom: 1,
		pos: { x: 0, y: 0 }, // viewport position
		mousePos: { x: 0, y: 0 },
		draggingOffset: { x: 0, y: 0 },
		dragging: false,
		wasDragging: false,
		query: null,
		hoverNode: null,
		hoverRef: null,
		selectedNodes: [],
		debugMousePos: { x: 0, y: 0 },
		mode: 'NATURAL', // NATURAL | WHEELZOOM
	}),
	computed: {
		// TODO: move this comlexity into the queries comp?
		// TODO: somehow during dragging, the coords of these objects dont get updated
		entryLookup() {
			const r = {}
			this.queryResults.forEach(result => result.entries.forEach(entry => r[entry?.sys?.id] = entry))
			return r
		},
		entries() {
			return Object.values(this.entryLookup)
		},
		nodes() {
			return this.graph?.fields?.nodes?.[this.defaultLocale] ?? []
		},
		nodeLookup() {
			return this.nodes.reduce((acc, node) => { acc[node.entryId] = node; return acc }, {})
		},
		queries() {
			return this.graph?.fields?.queries?.[this.defaultLocale] ?? []
		},
		queryLookup() {
			return this.queries.reduce((acc, query) => { acc[query.id] = query; return acc }, {})
		},
		node2query() {
			const r = {}
			for (const result of this.queryResults) {
				for (const entry of result.entries) {
					if (r[entry.sys.id]) continue
					r[entry.sys.id] = result.query
				}
			}
			return r
		},
		links() {
			const links = []
			if (!this.entries) return links
			for (const entry of this.entries) {
				for (const f in entry.fields) {
					const field = entry.fields[f]
					const value = field[this.defaultLocale]
					if (!value) continue
					if (Array.isArray(value)) {
						for (const val of value) {
							if (val.sys?.type !== 'Link') continue
							if (!val.sys?.linkType) continue
							if (val.sys.linkType !== 'Entry') continue
							links.push({ node1: entry.sys.id, node2: val.sys.id, rel: f })
						}
					}
					if (value.sys?.type == 'Link') {
						if (!value.sys?.linkType) continue
						links.push({ node1: entry.sys.id, node2: value.sys.id, rel: f })
					}
				}
			}
			for (const link of this.graph?.fields?.links[this.defaultLocale] ?? []) {
				if (link.deleted) continue
				links.push(link)
			}
			return links
		},
		marqueeXStart() { return Math.min(this.draggingStart?.x ?? 0, this.mousePos.x) },
		marqueeYStart() { return Math.min(this.draggingStart?.y ?? 0, this.mousePos.y) },
		marqueeXEnd() { return Math.max(this.draggingStart?.x ?? 0, this.mousePos.x) },
		marqueeYEnd() { return Math.max(this.draggingStart?.y ?? 0, this.mousePos.y) },
	},
	watch: {
		queries: {
			handler() {
				// TODO: dont change on pure name or color changes
				this.loadQueries()
			},
			deep: true,
		},
	},
	methods: {
		num(n) {
			n = parseFloat(n)
			if (!n.toFixed) return 0
			return n.toFixed(3)
		},
		// TODO: centralise somewhere
		getTitle(entry, short) {
			const t = window['typeLookup'][entry.sys.contentType.sys.id]
			const df = t?.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			if (short && entry?.fields?.short?.[dl]) return entry.fields.short[dl]
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
		contextmenu(e) {
			const entry = this.entryLookup[this.hoverNode?.entryId]
			this.$refs.menu.showAt(e.pageX - 32, e.pageY - 15, this.hoverNode, this.hoverRef, entry,
				{ x: this.mousePos.x, y: this.mousePos.y },
			)
			e.preventDefault()
		},
		mousedown(e) {
			this.wasDragging = false
			if (e.button !== 0) return
			this.dragging = true
			this.mml = window.addEventListener('mousemove', this.mousemoveDragging)
			this.mul = window.addEventListener('mouseup', this.mouseupDragging)
			this.draggingOffset.x = e.clientX - this.pos.x
			this.draggingOffset.y = e.clientY - this.pos.y
			this.draggingStart = this.mousePos
			e.preventDefault()
		},
		mousemoveDragging(e) {
			this.wasDragging = true
			if (this.mode == 'NATURAL') {
				if (this.dragging) {
					this.selectedNodes = this.nodes.filter(n =>
						n.x > this.marqueeXStart && n.x < this.marqueeXEnd
						&& n.y > this.marqueeYStart && n.y < this.marqueeYEnd)
				}
			}
			if (this.mode == 'WHEELZOOM') {
				this.pos.x = e.clientX - this.elPos.x - this.draggingOffset.x
				this.pos.y = e.clientY - this.elPos.x - this.draggingOffset.y
			}
		},
		mouseupDragging(e) {
			this.dragging = false
			window.removeEventListener('mousemove', this.mousemoveDragging)
			window.removeEventListener('mouseup', this.mouseupDragging)
		},
		mousemove(e) {
			this.debugMousePos = { x: e.x - this.elPos.x, y: e.y - this.elPos.y }
			const w = this.$refs.canvas.clientWidth
			const h = this.$refs.canvas.clientHeight
			const p = {
				x: (e.x - this.elPos.x - this.pos.x) / this.zoom,
				// TODO: magic "40" is the height of the toolbar
				y: (e.y - this.elPos.y - this.pos.y) / this.zoom,
			}
			this.mousePos = p
			this.$refs.arrowDragger.updateMousePos(p)
		},
		zoomBy(e, factor = 0.1) {
			const tz = this.zoom * (1 + factor)
			if (factor < 0 && tz < 0.2) return
			if (factor > 0 && tz > 5) return
			this.zoom = tz
			this.pos.x -= (e.x - this.elPos.x - this.pos.x) * factor
			this.pos.y -= (e.y - this.elPos.y - this.pos.y) * factor
		},
		zoomToFit() {
			const bounds = { x1: 9999, y1: 9999, x2: -9999, y2: -9999 }
			const r = 40
			if (this.nodes?.length == 0) return
			for (const node of this.nodes) {
				bounds.x1 = Math.min(bounds.x1, node.x - r)
				bounds.y1 = Math.min(bounds.y1, node.y - r)
				bounds.x2 = Math.max(bounds.x2, node.x + r)
				bounds.y2 = Math.max(bounds.y2, node.y + r)
			}
			const w = this.$refs.canvas.clientWidth
			const h = this.$refs.canvas.clientHeight
			const dx = bounds.x2 - bounds.x1
			const dy = bounds.y2 - bounds.y1
			this.zoom = Math.min(w / dx, h / dy)
			this.pos.x = -bounds.x1 * this.zoom + (w - dx * this.zoom) / 2
			this.pos.y = -bounds.y1 * this.zoom + (h - dy * this.zoom) / 2
		},
		storeViewport() {
			this.graph.fields.view[this.defaultLocale] = {
				pos: { x: this.pos.x.toFixed(3), y: this.pos.y.toFixed(3) },
				zoom: this.zoom,
			}
		},
		handleWheel(e) {
			let factor = e.wheelDeltaY * 0.0005
			if (this.mode == 'NATURAL') {
				if (e.ctrlKey) {
					// TODO: this seems to be dependent on something. sometimes 0.1 is too slow, sometimes 0.3 too fast.
					//       maybe canvas size? what does delta depend on?
					factor *= 0.33
					this.zoomBy(e, factor)
				}
				else {
					this.pos.x -= e.deltaX
					this.pos.y -= e.deltaY
				}
			}
			if (this.mode == 'WHEELZOOM') {
				// also support zoom gesture on touchpad
				if (e.ctrlKey) factor *= 0.1
				this.zoomBy(e, factor)
			}
			e.preventDefault()
			e.stopPropagation()
			return false
		},
		async loadQueries() {
			this.queryResults = []
			const nodeLookup = Object.fromEntries(Object.entries(this.nodeLookup))
			for (const query of this.queries) {
				// we copy filter so we dont have to modify in-place (this would cause update loop)
				const filter = JSON.parse(JSON.stringify(query.filter))
				filter.allFields = true
				const result = {
					query,
					entries: (await loadEntries(this.endpoint, filter, 10, 0)).items
				}
				// TODO: create node objects if needed
				for (const entry of result.entries) {
					if (nodeLookup[entry.sys.id]) continue
					// create a new node object on demand
					const node = {
						entryId: entry.sys.id,
						entryType: entry.sys.contentType.sys.id,
						x: 20 + Math.random() * 500,
						y: 20 + Math.random() * 50,
						// TODO: this should be updated on entry change, currently it gets frozen..
						//       or is it better to allow renaming nodes?
						title: this.getTitle(entry, true),
						links: [],
						queryId: query.id,
						removed: false,
					}
					nodeLookup[entry.sys.id] = node
				}
				this.queryResults.push(result)
			}
			this.graph.fields.nodes[this.defaultLocale] = Object.values(nodeLookup)
		},
		async load() {
			const graph = await this.$httpGet(this.endpoint + '/entries/' + this.id)

			// fix legacy data
			if (!graph.fields.nodes) graph.fields.nodes = {}
			for (const node of graph.fields.nodes[this.defaultLocale] ?? []) {
				if (!node.removed) node.removed = false
				if (!node.entryType) node.entryType = null
			}
			if (!graph.fields.links) graph.fields.links = {}
			if (!graph.fields.links[this.defaultLocale]) graph.fields.links[this.defaultLocale] = []

			this.graph = graph

			const view = this.graph.fields.view?.[this.defaultLocale]
			this.pos = { x: Number(view?.pos?.x) ?? 0, y: Number(view?.pos?.y) ?? 0 }
			this.zoom = view?.zoom ?? 1
		},
		async save() {
			await this.saveEntry(this.graph)
		},
		selectNode(node, e) {
			if (e.ctrlKey || e.metaKey) {
				if (this.selectedNodes.includes(node)) {
					// TODO: splice instead?
					this.selectedNodes = this.selectedNodes.filter(n => n !== node)
				}
				else {
					this.selectedNodes.push(node)
				}
			}
			else {
				this.selectedNodes = [ node ]
			}
		},
		dragNode(node, e) {
			for (const n of this.selectedNodes) {
				if (n == node) continue
				n.x += e.dx
				n.y += e.dy
			}
		},
		clickCanvas(e) {
			this.$refs.menu.hide()
			if (e.target == this.$refs.canvas) {
				if (!this.wasDragging)
					this.selectedNodes = []
			}
		},
		addNode(e) {
			const title = prompt('Node Title')
			if (!title) return
			const node = {
				entryId: 'NEW-' + Math.random().toString(36).substring(2, 10).toUpperCase(),
				entryType: null,
				x: e.pos.x,
				y: e.pos.y,
				title,
				links: [],
				queryId: null,
				removed: false,
			}
			this.graph.fields.nodes[this.defaultLocale].push(node)
		},
		addLink(e) {
			// TODO: distinguish between "tentative" (gray) and "real" links
			//e.node1.links.push({ entryId: e.node2.entryId, rel: 'NEW' })
			const link = { node1: e.node1.entryId, node2: e.node2.entryId, rel: 'NEW', color: null, deleted: false }
			this.graph.fields.links[this.defaultLocale].push(link)
		},
		removeLink(e) {
			// TODO: splice instead
			this.graph.fields.links[this.defaultLocale] = this.graph.fields.links[this.defaultLocale].filter(l => l != e)
		},
		forceLayoutAnimated(duration = 1000, stepDuration = 20) {
			const start = Date.now()
			const intervalId = window.setInterval(() => {
				this.forceLayoutIteration(1)
				if (Date.now() > start + duration) window.clearInterval(intervalId)
			}, stepDuration)
		},
		// use CoLa instead? https://github.com/tgdwyer/WebCola
		forceLayoutIteration(iterations = 50) {
			// move arrow targets into comfortable distance of source
			for (let i = 0; i < iterations; i++) {
				for (const link of this.links) {
					const node1 = this.nodeLookup[link.node1]
					const node2 = this.nodeLookup[link.node2]
					if (!node1 || !node2) continue
					if (node1 == node2) continue
					const dx = node1.x - node2.x
					const dy = node1.y - node2.y
					const d = Math.sqrt(dx * dx + dy * dy)
					let force = 0
					if (d < 100) force = 1
					if (d > 200) force = -1
					if (force == 0) continue
					if (!node2.dragging) {
						node2.x -= dx / d * force
						node2.y -= dy / d * force
					}
				}
				// bucketing: a node will be in all buckets that are "overlapping" it
				// so for the local repelling force calculation, we only need to look at nodes in the same bucket
				const BUCKET_SIZE = 100
				const buckets = {}
				for (const node of this.nodes) {
					const x = Math.floor(node.x / BUCKET_SIZE)
					const y = Math.floor(node.y / BUCKET_SIZE)
					for (let ox = -1; ox < 1; ox++) {
						for (let oy = -1; oy < 1; oy++) {
							const i = (x + ox) + '|' + (y + oy)
							if (!buckets[i]) buckets[i] = []
							buckets[i].push(node)
						}
					}
				}

				// move nodes away from each other
				for (const node1 of this.nodes) {
					const x = Math.floor(node1.x / BUCKET_SIZE)
					const y = Math.floor(node1.y / BUCKET_SIZE)
					const i = (x) + '|' + (y)
					for (const node2 of buckets[i]) {
						if (node1 == node2) continue
						const dx = node1.x - node2.x
						const dy = node1.y - node2.y
						const d = Math.sqrt(dx * dx + dy * dy)
						let force = 0
						if (d < 55) force = 1
						if (force == 0) continue
						if (!node1.dragging) {
							node1.x += dx / d * force
							node1.y += dy / d * force
						}
					}
				}
			}
		},
/* TODO: maybe for tablets? for touchpad this is useless - the mac touchpad uses wheel events.
		evCache: [],
		prevDiff: -1,
		// TODO: move to a new canvas comp?
		initPointerEvents(el) {
			el.onpointerdown = this.pointerdown
			el.onpointermove = this.pointermove
			el.onpointerup = this.pointerup
			el.onpointercancel = this.pointerup
			el.onpointerout = this.pointerup
			el.onpointerleave = this.pointerup
		},
		pointerdown(e) {
			this.evCache.push(e)
			this.log('pointerDown', e)
		},
		pointermove(e) {
			// This function implements a 2-pointer horizontal pinch/zoom gesture.
			//
			// If the distance between the two pointers has increased (zoom in),
			// the target element's background is changed to "pink" and if the
			// distance is decreasing (zoom out), the color is changed to "lightblue".
			//
			// This function sets the target element's border to "dashed" to visually
			// indicate the pointer's target received a move event.
			this.log('pointerMove', e)

			// Find this event in the cache and update its record with this event
			const index = this.evCache.findIndex(
				(cachedEv) => cachedEv.pointerId === e.pointerId,
			)
			this.evCache[index] = e

			// If two pointers are down, check for pinch gestures
			if (this.evCache.length === 2) {
				// Calculate the distance between the two pointers
				const curDiff = Math.abs(this.evCache[0].clientX - this.evCache[1].clientX)

				if (prevDiff > 0) {
					if (curDiff > this.prevDiff) {
						// The distance between the two pointers has increased
						this.log('Pinch moving OUT -> Zoom in', e)
					}
					if (curDiff < this.prevDiff) {
						// The distance between the two pointers has decreased
						this.log('Pinch moving IN -> Zoom out', e)
					}
				}

				// Cache the distance for the next move event
				this.prevDiff = curDiff
			}
		},
		pointerup(e) {
			this.log(e.type, e)
			this.removeEvent(e)
			if (this.evCache.length < 2) {
				this.prevDiff = -1
			}
		},
		removeEvent(e) {
			const index = this.evCache.findIndex((cachedEv) => cachedEv.pointerId === e.pointerId)
			this.evCache.splice(index, 1)
		},
		log(prefix, ev) {
			const s =
				`${prefix}:` +
				`  pointerID = ${ev.pointerId}` +
				`  pointerType = ${ev.pointerType}` +
				`  isPrimary = ${ev.isPrimary}`
			console.log(s)
			//this.$refs.debug.innerHTML += `${s}<br>`
		},
		this.initPointerEvents(this.$refs.canvas)
*/
	},
	async mounted() {
		this.id = this.$route.params.id
		try {
			await this.load()
			await this.loadQueries()
		}
		catch (e) {
			// when the server gave an error, we assume the entry does not exist yet
			if (e?.sys?.type != 'Error') throw e
		}
		if (!this.graph) {
			const dl = this.defaultLocale
			this.graph = await this.$httpPut(this.endpoint + '/entries/' + this.id, {
				fields: {
					title: { [dl]: 'New Graph' },
					queries: { [dl]: [] },
					nodes: { [dl]: [] },
					links: { [dl]: [] },
					view: { [dl]: { pos: { x: 0, y: 0 }, zoom: 1 } },
				},
			}, {
				headers: {
					'X-Contentful-Content-Type': 'x_graph',
				},
			})
		}
	},
}
</script>

<style scoped>
.NodeGraph { display: flex; position: absolute; inset: 0; }
.canvas { flex: 1; overflow: hidden; }
.sidebar { flex: 0 0 300px; padding: 10px; }
.selectionMarquee { position: absolute; outline: 2px dotted var(--primary); opacity: 0.5; }

@media screen and (max-width: 800px) {
	.sidebar { flex: 0 0 200px; }
}
</style>

<style>
.zoom100 .Node,
.zoom100 .Link { font-size: 9px; }
.zoom150 .Node,
.zoom150 .Link { font-size: 7px; }
.zoom200 .Node,
.zoom200 .Link { font-size: 6px; }
</style>