<template>
	<div class="Node"
		v-if="!value?.removed"
		@mousedown="mousedown"
		@mouseover="$emit('mouseover', $event)"
		@mouseout="$emit('mouseout', $event)"
		@mouseup="mouseup"
		:class="{ dragging, selected }"
		:style="{
			left: (value.x - 25) + 'px',
			top: (value.y - 25) + 'px',
			background: query?.color,
		}"
	>
		<div class="label">
			{{ label }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'Node',
	inject: [ 'defaultLocale' ],
	props: {
		// { entryId, x, y, title, links: [ { id, rel } ] }
		value: Object,
		entry: Object,
		query: Object,
		mousePos: Object,
		selected: { type: Boolean, default: false },
	},
	data: () => ({
		mdt: null, // mouse down time
		wmml: null, // mouse move listener on window
		wmul: null, // mouse up listener on window
		offset: { x: 0, y: 0 },
		dragging: false,
	}),
	computed: {
		label() {
			// NOTE that NodeGraph has a getTitle() method to build the title later,
			return this.value.title
				?? this.entry?.fields?.title?.[ defaultLocale ]
		},
	},
	methods: {
		mousedown(e) {
			if (e.button !== 0) return
			this.dragging = true
			this.value.dragging = true
			this.mdt = Date.now()
			this.wmml = window.addEventListener('mousemove', this.windowMousemove)
			this.wmul = window.addEventListener('mouseup', this.windowMouseup)
			this.offset.x = this.value.x - this.mousePos.x
			this.offset.y = this.value.y - this.mousePos.y
			e.preventDefault()
			e.stopPropagation()
		},
		windowMousemove(e) {
			e.dx = this.mousePos.x + this.offset.x - this.value.x
			e.dy = this.mousePos.y + this.offset.y - this.value.y
			this.value.x += e.dx
			this.value.y += e.dy
			this.$emit('drag', this.value, e )
		},
		// TODO: also cancel on window change
		windowMouseup(e) {
			this.dragging = false
			this.value.dragging = undefined
			window.removeEventListener('mousemove', this.windowMousemove)
			window.removeEventListener('mouseup', this.windowMouseup)
			this.wmml = null
			this.wmul = null
		},
		mouseup(e) {
			if (Date.now() - this.mdt < 300) {
				this.$emit('select', this.value, e)
			}
			this.$emit('mouseup', this.value, e)
		},
	},
}
</script>

<style scoped>
.Node { z-index: 99; position: absolute; display: flex; align-items: center; text-align: center; font-size: 11px; width: 50px; height: 50px; padding: 3px; box-sizing: border-box; background: silver; border-radius: 25px; overflow: hidden; }
.label { width: 100%; color: white; pointer-events: none; }
.dragging { outline: 1px solid white; box-shadow: 4px 4px 15px rgba(0,0,0,0.2); z-index: 999; }
.selected { outline: 2px solid var(--primary); outline-offset: 1px; }
</style>