
import EntryStatus from './fields/EntryStatus.vue'

// TODO: v-model should be selection!

export default {
	name: 'ReferencesTreeNode',
	components: { EntryStatus },
	inject: [ 'defaultLocale', 'fallbackLocale' ],
	props: {
		node: Object,
		value: Object, // selection model
	},
	data: () => ({
	}),
	computed: {
		contentType() {
			if (!this.node?.entry?.sys) return undefined
			const id = this.node.entry.sys.contentType.sys.id
			return window['typeLookup'][id]
		},
		title() {
			const entry = this.node?.entry
			const df = this.contentType?.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
	},
	methods: {
		subedit() {
			if (this.node.entry?.sys?.type == 'error') return
			if (!this.node.entry?.sys) return
			// TODO: we should reload the entry in the callback, so the status + title get updated
			this.$emit('subedit', this.node.entry.sys, () => {})
		},
		forwardSubedit(link, callback) {
			this.$emit('subedit', link, callback)
		},
	},
}
